export default [
    {
        path: "/register",
        component: () => import("../views/Register.vue"),
        name: "Register"
    },
    {
        path: "/verify",
        component: () => import("../views/Confirm.vue"),
        name: "Verify"
    },
    {
        path: "/forgot-password",
        component: () => import("../views/ResetPasswordRequest.vue"),
        name: "ForgotPassword"
    },
    {
        path: "/reset-password",
        component: () => import("../views/ResetPassword.vue"),
        name: "ResetPassword"
    },
    {
        path: "/login",
        component: () => import("../views/Login.vue"),
        name: "Login"
    },
    {
        path: "/customer",
        component: () => import("../views/CustomerPanel.vue"),
        name: "CustomerPanel"
    },
    {
        path: "/refill",
        component: () => import("../views/Refill.vue"),
        name: "Refill"
    },
    {
        path: "/change-phone-number",
        component: () => import("../views/ChangePhoneNumber.vue"),
        name: "ChangePhoneNumber"
    },
    {
        path: "/telebirr",
        component: () => import("../views/TelebirrTransfer.vue"),
        name: "Telebirr"
    },
    {
        path: "/transaction-list",
        component: () => import("../views/TransactionList.vue"),
        name: "TransactionList"
    },
    // {
    //     path: "/",
    //     component: () => import("../views/Home.vue"),
    //     name: "Home"
    // },
    {
        path: "/details",
        component: () => import("../views/Details.vue"),
        name: "Details"
    },
    {
        path: "/",
        component: () => import("../views/Welcome.vue"),
        name: "Welcome",
    },
    {
        path: "/international",
        component: () => import("../views/InternationalServices.vue"),
        name: "IServices",
    },
    {
        path: "/calling-rates",
        component: () => import("../views/InternationalCallingRates.vue"),
        name: "CallingRates",
    },
    {
        path: "/support",
        component: () => import("../views/ContactSupport.vue"),
        name: "ContactSupport",
    },
    {
        path: "/transfers",
        component: () => import("../views/TransfersList.vue"),
        name: "TransfersList",
    },
    {
        path: "/invite-bonus",
        component: () => import("../views/InviteBonus.vue"),
        name: "InviteBonus",
    },
    {
        path: "/t-elephant",
        component: () => import("../views/T-elephant.vue"),
        name: "Telephant",
    },
    {
        path: "/how-it-work",
        component: () => import("../views/HowItWork.vue"),
        name: "HowItWork",
    },
    {
        path: "/return",
        component: () => import("../views/ThankYou.vue"),
        name: "ThankYou",
    },

]