<template>
  <div>
    <div class="text-center">
      <div>
        <transition name="fade">
          <h3 v-if="!showSuccess" class="modal-title w-100 h4 font-weight-bold">
            {{ $t("translate.registration_modal.register_button") }}
          </h3>
        </transition>
        <transition name="fade">
          <h3 v-if="showSuccess" class="modal-title w-100 h4 font-weight-bold">
            {{ $t("translate.registration_modal.thank_you_tittle") }}
          </h3>
        </transition>
        <transition name="fade">
          <div v-if="showSuccess">
            <p>
              {{ $t("translate.registration_modal.thank_you_message") }}
            </p>
          </div>
        </transition>
      </div>

      <div class="text-center d-flex align-items-center justify-content-center">
        <transition name="fade">
          <div v-if="!showSuccess">
            <div class="group-56">
              <div class="overlap-6">
                <cake-input
                  v-model="details.firstname"
                  v-bind:type="'text'"
                  v-bind:errors="errors.firstname"
                  v-bind:identifier="'first_name'"
                  v-bind:placeholder="$t('translate.registration_modal.firs_name')"
                  v-bind:isEnabled="isRequestPending"
                />
              </div>
            </div>
            <div class="group-57">
              <div class="overlap-6">
                <cake-input
                  v-model="details.lastname"
                  v-bind:type="'text'"
                  v-bind:errors="errors.lastname"
                  v-bind:identifier="'last_name'"
                  v-bind:placeholder="$t('translate.registration_modal.last_name')"
                  v-bind:isEnabled="isRequestPending"
                />
              </div>
            </div>
            <div class="group-58">
              <div class="overlap-6">
                <cake-input
                  v-model="details.email"
                  v-bind:type="'email'"
                  v-bind:errors="errors.email"
                  v-bind:identifier="'email'"
                  v-bind:placeholder="$t('translate.registration_modal.email')"
                  v-bind:isEnabled="isRequestPending"
                />
              </div>
            </div>
            <div class="group-60">
              <div class="overlap-6">
                <cake-input
                  v-model="details.password"
                  v-bind:type="'password'"
                  v-bind:errors="errors.password"
                  v-bind:identifier="'password'"
                  v-bind:placeholder="$t('translate.registration_modal.password')"
                  v-bind:isEnabled="isRequestPending"
                />
              </div>
            </div>
            <div class="group-62">
              <div class="overlap-8">
                <cake-input
                  v-model="details.confirmpassword"
                  v-bind:type="'password'"
                  v-bind:errors="errors.confirmpassword"
                  v-bind:identifier="'confirmpassword'"
                  v-bind:placeholder="$t('translate.registration_modal.repeat_password')"
                  v-bind:isEnabled="isRequestPending"
                />
              </div>
            </div>
            <div class="group-59">
              <div class="overlap-6">
                <cake-input
                  v-model="details.phone"
                  v-bind:type="'text'"
                  v-bind:errors="errors.phone"
                  v-bind:identifier="'phone'"
                  v-bind:placeholder="$t('translate.registration_modal.phone_number')"
                  v-bind:isEnabled="isRequestPending"
                />
              </div>
              <transition name="fade">
                <div
                  v-if="details.phone != ''"
                  class="pb-1 country-code-info text-center"
                >
                  <p>
                    {{ $t("translate.registration_modal.enter_country_code") }} <br />
                    {{ countryCode }} {{ $t("translate.registration_modal.for") }}
                    {{ countryName }}
                  </p>
                </div>
              </transition>
            </div>
            <div class="group-63">
              <div class="overlap-7">
                <cake-input
                  v-model="details.refcode"
                  v-bind:type="'text'"
                  v-bind:errors="errors.refcode"
                  v-bind:identifier="'refcode'"
                  v-bind:placeholder="$t('translate.registration_modal.referal_code')"
                  v-bind:isEnabled="isRequestPending"
                />
              </div>
            </div>

            <input
              class="group-61"
              type="checkbox"
              id="checkbox_id"
              name="terms"
              :value="details.acceptTerms"
            />
            <span class="p mt-4">
              {{ $t("translate.registration_modal.tos_first_part") }} <br /><b
                class="bolded"
                ><a href=""> {{ $t("translate.registration_modal.tos") }}</a></b
              >
              {{ $t("translate.registration_modal.and") }}

              <b class="bolded"
                ><a href=""> {{ $t("translate.registration_modal.pp") }}</a></b
              ></span
            >
          </div>
        </transition>
      </div>
      <transition name="fade">
        <div class="my-3" v-if="!showSuccess">
          <div class="overlap-group">
            <button class="btn-register" :disabled="isRequestPending" @click="register">
              <span v-if="!isRequestPending">
                {{ $t("translate.registration_modal.register_button") }}</span
              >
              <span v-if="isRequestPending">{{
                $t("translate.registration_modal.sending_info")
              }}</span>
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import CakeInput from "../components/CakeInput.vue";

export default {
  components: {
    CakeInput,
  },

  data: function () {
    return {
      acceptTermsMessage: false,
      details: {
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        confirmpassword: "",
        phone: "",
        refcode: "",
      },
      isRequestPending: false,
      errors: [],
      showSuccess: false,
      acceptTerms: false,
      countryName: "",
      countryCode: "",
    };
  },

  mounted() {
    this.getGeoInfo();
  },
  methods: {
    register: function () {
      this.isRequestPending = true;
      window.axios
        .post("/api/customers/users/register", this.details)
        .then(() => {
          this.isRequestPending = false;
          this.showSuccess = true;
        })
        .catch((err) => {
          this.isRequestPending = false;
          this.errors = err.response.data;
        });
    },
    getGeoInfo() {
      window.axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          this.countryName = data.country_name;
          this.countryCode = data.country_calling_code;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.overlap-succes {
  position: relative;
  width: 520px;
  height: 55px;
  top: 120px;
  font-size: 20px;
  left: -46px;
  background-color: #ffffff;
}
.rectangle-succes {
  position: absolute;
  width: 737px;
  height: 285px !important;
  top: 0px;
  left: -136px;
  background-color: #ffffff;
  border-radius: 28px;
  box-shadow: 0px 3px 3px #7d7d7d29;
}

.fade-enter-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.text-wrapper-succes {
  top: 32px;
  left: 172px;
  font-weight: 600;
  color: #000000;
  font-size: 24px;
  line-height: normal;
  position: relative;
  font-family: "Montserrat", Helvetica;
  letter-spacing: 0;
  white-space: nowrap;
}

.checkbox-error {
  outline: 2px solid #dc3545;
}

.country-code-info {
  color: rgb(253, 133, 33);
}

</style>
