<template>
  <div class="background-footer">
    <footer>
      <div class="container-fluid">
        <div class="row gx-5 mb-5" v-if="isMobileDevice && isLogedIn">
          <div class="col-md">
            <img
              class="img-fluid pt-auto"
              alt="Responsive image"
              src="/img/telephant/djumbo-02-05-2.png"
            />
          </div>
          <!-- <div class="col-md text-center">
            <button
              class="my-4 bt-wrapper"
              data-target="#logInModal"
              data-toggle="modal"
              id="logInModalLink"
              href="#logInModal"
            >
              {{ $t("translate.footer.log_in") }}
            </button>
            <button
              class="bt-join"
              id="joinModal"
              data-toggle="modal"
              data-target="#joinModal"
            >
              {{ $t("translate.footer.join") }}
            </button>
          </div> -->
        </div>
        <div class="row gx-5" v-else>
          <div class="col-md">
            <img
              class="img-fluid pt-auto"
              alt="Responsive image"
              src="/img/telephant/djumbo-02-05-2.png"
            />
          </div>
          <div class="col-md">
            <div class="footer-bold-text-wrapper">
              {{ $t("translate.footer.services.title") }}
            </div>
            <!-- <router-link class="footer-text-wrappe" type="button" to="international"
              >International top-up
            </router-link> -->
            <router-link class="footer-text-wrappe" type="button" to="calling-rates"
              ><div>{{ $t("translate.footer.services.inter_calling_rates") }}</div>
            </router-link>
          </div>
          <div class="col-md">
            <div class="footer-bold-text-wrapper">
              {{ $t("translate.footer.help.title") }}
            </div>
            <router-link class="footer-text-wrappe" type="button" to="support"
              >{{ $t("translate.footer.help.contact_support") }}
            </router-link>         
            <a class="footer-text-wrappe"   @click="getCookie()">
              <span>{{ $t("translate.footer.help.cookie") }}</span>
            </a>           <br>
            <a class="footer-text-wrappe"  @click="getTermsOfService()">
              <span>{{ $t("translate.footer.help.tos") }}</span>
            </a>             <br>
            <a class="footer-text-wrappe" @click="getPrivacyPolicy()">
              <span>{{ $t("translate.footer.help.pp") }}</span>
            </a>          <br>
            <router-link class="footer-text-wrappe" type="button" to="invite-bonus"
              >{{ $t("translate.footer.help.ib") }}
            </router-link>
          </div>
          <div class="col-md">
            <div class="footer-bold-text-wrapper">
              {{ $t("translate.footer.about_us.title") }}
            </div>
            <router-link class="footer-text-wrappe" type="button" to="t-elephant"
              >{{ $t("translate.footer.about_us.t_elephant") }}
            </router-link>
            <br />
            <router-link class="footer-text-wrappe" type="button" to="how-it-work"
              >{{ $t("translate.footer.about_us.how_it_works") }}
            </router-link>
          </div>
          <div class="col-md">
            <div class="footer-bold-text-wrapper">
              {{ $t("translate.footer.product.title") }}
            </div>
            <a class="footer-text-wrappe" href="">
              <span>{{ $t("translate.footer.product.easy_calling") }}</span>
            </a> <br>
            <router-link class="footer-text-wrappe" type="button" to="calling-rates">
              {{ $t("translate.footer.product.calling_rates") }}
            </router-link>
          </div>
          <div class="col-md">
            <div class="footer-bold-text-wrapper">
              {{ $t("translate.footer.language.title") }}
            </div>
            <a class="footer-text-wrappe" @click="$i18n.locale = 'gb'">
              <span>{{ $t("translate.footer.language.en") }}</span>
            </a> <br>
            <a class="footer-text-wrappe" @click="$i18n.locale = 'de'">
              <span>{{ $t("translate.footer.language.de") }}</span>
            </a>
            <!-- <a class="footer-text-wrappe" @click="$i18n.locale = 'ru'">
              <div>{{ $t("translate.footer.language.ru") }}</div>
            </a> -->
          </div>
          <div class="col-md">
            <button
              class="my-4 bt-wrapper"
              data-target="#logInModal"
              data-toggle="modal"
              id="logInModalLink"
              href="#logInModal"
              @click="$emit('open')"
            >
              {{ $t("translate.footer.log_in") }}
            </button>
            <button
              class="bt-join"
              id="joinModal"
              data-toggle="modal"
              data-target="#joinModal"
            >
              {{ $t("translate.footer.join") }}
            </button>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return { isMobileDevice: false };
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      this.isMobileDevice = true;
     
    } else {
      // false for not mobile device
      this.mobileDevice = false;
    
    }
  },
  computed: {
    isLogedIn: {
      get() {
        return this.$store.getters.logedSatus;
      },
    },
  },
  methods: {
    getTermsOfService() {
      this.isRequestPending = true;
      window.axios
        .get("/api/customers/users/temrsOfServicesPDF", { responseType: "blob" })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let translationName = this.$t("translate.termsOfService_pdf");
          fileLink.setAttribute("download", translationName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    getCookie() {
      this.isRequestPending = true;
      window.axios
        .get("/api/customers/users/cookiesPDF", { responseType: "blob" })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let translationName = this.$t("translate.cookies_pdf");
          fileLink.setAttribute("download", translationName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    getPrivacyPolicy() {
      this.isRequestPending = true;
      window.axios
        .get("/api/customers/users/privacyPolicyPDF", { responseType: "blob" })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let translationName = this.$t("translate.footer.help.pp");
          fileLink.setAttribute("download", translationName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
  },
};
</script>

<style>
.footer-bold-text-wrapper {
  font-weight: 700;
  line-height: 2;
  font-family: "Montserrat", Helvetica;
  color: #000000;
  font-size: 1rem;
  letter-spacing: 0;
  z-index: 10;
  white-space: nowrap;
}

.bt-join {
  font-family: "Montserrat", Helvetica;
  border-radius: 43px;
  width: 10.5rem;
  height: 3.5rem;
  background: white;
  border-color: orange;
}

.footer-text-wrappe {
  font-family: "Montserrat", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 2.3;
  white-space: nowrap;
  z-index: 10;
}

.background-footer {
  position: relative;
  z-index: 20;
  background: white;
}
</style>
