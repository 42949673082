window.axios = require('axios');

import Vue from 'vue'
Vue.prototype.$APIPrefix = '/customers/';

import App from './App.vue'
import router from './router';
import  {i18n} from  "./translation/vue-i18n";
import store from './store';
import VueGtag from "vue-gtag";

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false
Vue.component('app-component', App);
Vue.use(VueGtag, {
  config: { id: "G-2RST97ZKHH" },
  pageTrackerScreenviewEnabled: true
}, router);


new Vue({
  el: '#app',
  router,
  store,
  i18n,
});
