<template>
  <div class="">
    <div class="collors-wrapper">
      <div class="img-topbar-red">
        <img class="img-fluid" src="/img/telephant/rectangle-467-3.png" />
      </div>
      <div class="img-topbar-green">
        <img class="img-fluid" src="/img/telephant/rectangle-461-2.png" />
      </div>
    </div>
    <nav class="navbar navbar-light navbar-expand-lg topbar mb-4 static-top index-top">
      <router-link class="navbar-brand" type="button" to="/"
        ><img class="" src="/img/telephant/djumbo-02-05-1.png" />
      </router-link>
      <button
        class="navbar-toggler mt-auto ml-1 mr-auto"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto ml-auto form-inline">
          <li class="nav-item dropdown">
            <a
              class="nav-link mt-2 dropdown text-wrapp"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ $t("translate.top_bar.services.title") }}
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdown">
              <router-link class="dropdown-item" type="button" to="international"
                >{{ $t("translate.top_bar.services.authentication") }}
              </router-link>
              <router-link class="dropdown-item" type="button" to="calling-rates"
                >{{ $t("translate.top_bar.services.inter_calling_rates") }}
              </router-link>
              <!-- <button
                class="dropdown-item"
                data-target="#refillModal"
                data-toggle="modal"
                id="refillModalLink"
                type="button"
                href="#topUPModal"
              >
                {{ $t("translate.top_bar.services.top_up") }}
              </button> -->
            </div>
          </li>
          <li>
            <a
              class="nav-link dropdown mt-2 text-wrapp"
              data-target="#refillModal"
              data-id="top_up_open"
              data-toggle="modal"
              id="top_up_open"
              type="button"
              href="#topUPModal"
              @click="openRefillTrack()"
            >
              {{ $t("translate.top_bar.services.top_up") }}
            </a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown mt-2 text-wrapp"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ $t("translate.top_bar.help.title") }}
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdown2">
              <router-link class="dropdown-item" type="button" to="support">
                {{ $t("translate.top_bar.help.contact_support") }}
              </router-link>
              <button class="dropdown-item" type="button" @click="getCookie()">
                {{ $t("translate.top_bar.help.cookie") }}
              </button>
              <!-- <button class="dropdown-item" type="button">
                {{ $t("translate.top_bar.help.legal_information") }}
              </button> -->
              <button class="dropdown-item" type="button" @click="getTermsOfService()">
                {{ $t("translate.top_bar.help.tos") }}
              </button>
              <button class="dropdown-item" type="button" @click="getPrivacyPolicy()">
                {{ $t("translate.top_bar.help.pp") }}
              </button>
              <router-link class="dropdown-item" type="button" to="invite-bonus"
                >{{ $t("translate.top_bar.help.ib") }}
              </router-link>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown mt-2 text-wrapp"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ $t("translate.top_bar.about_us.title") }}
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdown3">
              <router-link class="dropdown-item" type="button" to="t-elephant">
                {{ $t("translate.top_bar.about_us.t_elephant") }}
              </router-link>
              <router-link class="dropdown-item" type="button" to="how-it-work">
                {{ $t("translate.top_bar.about_us.how_it_works") }}
              </router-link>
            </div>
          </li>
        </ul>
        <ul class="navbar-nav mr-auto ml-auto form-inline">
          <li class="nav-item dropdown">
            <transition name="fade">
              <a
                v-if="!isLogedIn"
                class="nav-link mt-2"
                data-target="#joinModal"
                data-toggle="modal"
                id="joinModalLink"
                href="#joinModal"
              >
                {{ $t("translate.top_bar.join") }}
              </a>
            </transition>
            <transition name="fade">
              <a
                v-if="isLogedIn"
                class="nav-link mt-2 dropdown text-wrapp"
                data-toggle="modal"
                data-target="#logOutModal"
              >
                {{ $t("translate.top_bar.log_out") }}
              </a>
            </transition>
          </li>
          <div class="nav-item">
            <transition name="fade">
              <button
                v-if="!isLogedIn"
                class="nav-link mt-2 bt-wrapper-top-bar btn-link"
                data-target="#logInModal"
                @click="$emit('open')"
                data-toggle="modal"
                id="logInModalLink"
                href="#logInModal"
              >
                {{ $t("translate.top_bar.log_in") }}
              </button>
            </transition>
            <transition name="fade">
              <button v-if="isLogedIn" class="nav-link mt-2 bt-wrapper-top-bar btn-link">
                <router-link to="customer">
                  {{ $t("translate.top_bar.return") }}
                </router-link>
              </button>
            </transition>
          </div>
          <div class="locale-changer mt-2">
            <img :src="'/img/flags/' + $i18n.locale + '.svg'" class="img-flags ml-1" />
            <select v-model="$i18n.locale" class="locale-select">
              <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.code">
                <span class="bolded">
                  {{ lang.text }}
                </span>
              </option>
            </select>
          </div>
        </ul>
      </div>
    </nav>
    <div
      class="modal fade"
      id="logOutModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div>
              <p class="h3">{{ $t("translate.log_out_modal.message") }}</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-log-out mr-3" data-dismiss="modal">
              <span class="button-text-modal">{{
                $t("translate.log_out_modal.no")
              }}</span>
            </button>
            <button
              type="button"
              @click="logout()"
              data-dismiss="modal"
              class="btn-log-out mr-3"
            >
              <div class="button-text-modal">{{ $t("translate.log_out_modal.yes") }}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      langs: [
        { code: "gbr", text: "EN" },
        { code: "deu", text: "DE" },
        { code: "pol", text: "PL" },
        { code: "cze", text: "CZ" },
        // { code: "rus", text: "RU" },
      ],
    };
  },
  computed: {
    isLogedIn: {
      get() {
        return this.$store.getters.logedSatus;
      },
      set(value) {
        this.$store.commit("logedSatus", value);
      },
    },
    language: {
      get() {
        return this.$i18n.locale;
      },
    },
  },
  mounted() {
    window.axios.get("/api/customers/users/user").then((response) => {
      if (response.data) {
        this.isLogedIn = true;
      } else {
        this.isLogedIn = false;
      }
    });
  },
  methods: {
    openRefillTrack(){
      this.$gtag.pageview("/refill");
    },
    logout() {
      this.isRequestPending = true;
      window.axios
        .post("/api/customers/users/logout")
        .then(() => {
          this.isLogedIn = false;
          this.$router.replace({ name: "Welcome" });
        })
        .catch(() => {
          this.showError = true;
        })
        .finally(() => {
          this.isRequestPending = false;
        });
    },
    getTermsOfService() {
      this.isRequestPending = true;
      window.axios
        .get("/api/customers/users/temrsOfServicesPDF", { responseType: "blob" })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;

          let translationName = this.$t("translate.termsOfService_pdf");
          fileLink.setAttribute("download", translationName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    getCookie() {
      this.isRequestPending = true;
      window.axios
        .get("/api/customers/users/cookiesPDF", { responseType: "blob" })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;

          let translationName = this.$t("translate.cookies_pdf");
          fileLink.setAttribute("download", translationName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    getPrivacyPolicy() {
      this.isRequestPending = true;
      window.axios
        .get("/api/customers/users/privacyPolicyPDF", { responseType: "blob" })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let translationName = this.$t("translate.footer.help.pp");
          fileLink.setAttribute("download", translationName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
  },
  watch: {
    language(selectedLanguage) {
      this.isRequestPending = true;
      window.axios
        .post("/api/customers/users/languageChange", selectedLanguage)
        .then(() => {})
        .catch(() => {
          this.showError = true;
        })
        .finally(() => {
          this.isRequestPending = false;
        });
    },
  },
};
</script>

<style>
.index {
  z-index: 30;
}

.group-navbar {
  position: absolute;
  width: 100%;
  height: 55px;
  top: 693px;
  /* top: 0; */
  z-index: 12;
}

.locale-select {
  font-weight: bold !important;
  background: transparent !important;
  border: none !important;
}

.text-wrapp {
  font-weight: 400;
  font-family: "Montserrat", Helvetica;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  z-index: 12;
}

.navbar .navbar-brand {
  margin-left: 10% !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
}

.btn-text-wrapper {
  position: absolute;
  width: 50px;
  top: 16px;
  left: 24px;
  font-family: "Montserrat", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  white-space: nowrap;
}

.btn-log-out {
  position: relative;
  width: 100px;
  height: 55px;
  font-family: "Montserrat", Helvetica;
  border-radius: 43px;
  color: white;
  background: linear-gradient(180deg, rgb(253, 194, 2) 0%, rgb(253, 133, 33) 100%);
  border: white;
}

.h1 {
  color: #1f1f1f;
  font-family: "Montserrat", Helvetica;
}
.overlap-5 {
  border: white;
  font-family: "Montserrat", Helvetica;
}
.button-text-modal {
  font-family: "Montserrat", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 2.3;
}

.mr-10 {
  margin-right: 13rem !important;
}

form button {
  max-height: 3.5rem !important;
}

.collors-wrapper {
  position: relative;
  display: block;
}

.img-topbar-red {
  z-index: 15;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-20%, -60%);
}

.img-topbar-green {
  z-index: 15;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(40%, -50%);
}

.index-top {
  z-index: 100;
}
.img-flags {
  max-height: 25px;
  height: 5%;
}
</style>
