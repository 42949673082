export const translate = {
    top_bar: {
        services: {
            title: "Tips and Tricks",
            authentication: 'Authentication',
            inter_calling_rates: 'International calling rates',
            top_up: 'Top-up',
        },
        help: {
            title: "Help",
            contact_support: "Contact support",
            cookie: "Cookie policy",
            legal_information: "Legal Information",
            tos: "Terms of Service",
            pp: "Privacy Policy",
            ib: "Invite Bonus",
        },
        about_us: {
            title: "About us",
            t_elephant: "T-elephant",
            how_it_works: "How it works",
        },
        join: "Join",
        log_in: "Log in",
        log_out: "Log out",
        return: "Return to panel"
    },
    footer: {
        services: {
            title: "Services",
            inter_calling_rates: 'International calling rates',
        },
        help: {
            title: "Help",
            contact_support: "Contact support",
            cookie: "Cookie policy",
            legal_information: "Legal Information",
            tos: "Terms of Service",
            pp: "Privacy Policy",
            ib: "Invite Bonus",
        },
        about_us: {
            title: "About us",
            t_elephant: "T-elephant",
            how_it_works: "How it works",
        },
        product: {
            title: "Product",
            easy_calling: "Easy calling",
            calling_rates: "Calling rates",
        },
        language: {
            title: "Language",
            cz: "česky",
            en: "English",
            de: "German",
            ru: "Russian",
        },
        log_in: "Log in",
        join: "Join",
        return: "Return to panel",
        log_out: "Log out",
    },
    side_bar: {
        your_accoutn: "Your Account",
        user_profile: "User profile",
        billing: "Billing",
        call_history: "Call history",
    },
    welcome_page: {
        cheapy_and_easy: "Cheap and easy international phone calls",
        you_are_three: "You are three steps away from those closest to you in your country:",
        create_an_account_by: "create an account by providing your email address and, optionally, your phone number",
        top_up_your_account: "top up your account convenient form of payment",
        call_with_authorization: "call with authorization via PIN or telephone number",
        creating_account: "Creating an account",
        to_set_up_a_call_email: "To set up a call, all you need is an e-mail address",
        to_set_up_a_call_authorization: "You can also provide a phone number and authorize it to make calls without entering the PIN number",
        new_account: "New account",
        phone_number_authorization: "Phone number authorization",
        if_you_authorize_your_phone: "If you authorize your phone number, the call will not be made you need to enter your PIN",
        to_perform_authorization: " To perform authorization, log in to your account and go to settings profile and select the authorization method",
        the_lowest_prices: "The lowest prices and convenience of use",
        offers_the_cheapest_phone: "   T-elephant offers the cheapest phone calls to countries all over the world.",
        no_hidden_fees: "No hidden fees or need to install applications.",
        select_the_country_you_want: "Select the country you want to call - check prices, top up your account and call!",
        spend_your_money: " Spend your money on what's important, not on expensive phone calls.",
        international_calling: "Choose where you want to call and check our prices:",
        international_calling_placeholder: "Where do you want to call?",
        cirrcle_one_first_line: "the best international",
        cirrcle_one_second_line: "calling rates,",
        cirrcle_one_thrid_line: "best quality at the best price",
        cirrcle_two_first_line: " no need to get app,",
        cirrcle_two_second_line: "use your phone - easy",
        cirrcle_two_thrid_linie: "and fast – reach your",
        cirrcle_two_fourth_line: " family and friends!",
        cirrcle_three_first_line: "more than callingcard",
        cirrcle_three_second_line: "sing in to get",
        cirrcle_three_thrid_line: "extras/promo/bonus/reward",
        how_work_title: "How to make calls?",
        how_work_text_point_1: "1. Call the access number ",
        how_work_text_point_2: "2. Enter your PIN (if you authorize your phone number, your PIN will not be required)",
        how_work_text_point_3: "3. Enter your destination number and enjoy cheap calls!",
        Poland: "Poland:",
        Germany: "Germany:",
        Great_britan: "Great Britain:",
        text_button: "Learn more",
        world_credits: "World credits",
        cheapest: "Chepest possible rates to call ",
        and: "and",
        show_all_rates: "show all rates",
        buy_now: "Buy now",
        minutes: "min",
        per_minute: "per minute",
    },
    authentication_page: {
        first_title: "Easily authorize with your phone and enjoy!",
        text: "Telephant grants you secure access to your account. You can use the pin authentication or phone identification to be easy authorize calling via dial number. Go to the user profile and choose the appropraite way you like to use it.",
        secondt_title: "How to top up? it’s easy",
        first_step: "Log in into your T-elephant account",
        second_step: "Select the User Profile button",
        green_button_text: "User Profile",
        thrid_step: "Eneable the preffered option (it became the green bordered)"
    },
    inter_calling_rates_page: {
        text_title_bold: "International calling rates",
        text_title: "Try T-elephant international calls",
        list_first_el: "The cheapest international calls with high premium quality",
        list_secont_el: "No hidden fees - pay as you go",
        list_third_el: "No need to install any additional app",
        list_fourth_el: "No internet needed",
        bottom_text_small: "T-elephant give you the way to stay in touch with friends and family all over the world !",
        bottom_text_normal: "No matter from where you are calling to - you control your costs!",
        title_select: "Check our offer",
        select_placeholder: "Where do you want to call?",
        how_it_works: "How it works",
    },
    top_up_modal: {
        first_name_placeholder: "First Name",
        last_name_placeholder: "Last Name",
        email_placeholder: "E-mail",
        phone_number_placeholder: "Phone number",
        first_part_tos: "I accept T-Elephant",
        tos: "terms of service",
        and_int_tos: "and",
        pp: "privacy policy",
        button_text: "Top-up",
        top_up_summary: "Top-up Summary",
        for_calls: "for calls!",
        refill_value: "Refill value",
        pay_with_przelewy24: "Pay with Przelewy24",
        first_part_tos_P24: "I declare that I have read the",
        tos_P24: "regulations",
        and_int_tos_p24: "and ",
        pp_P24: "information obligation",
        sign_in: "Create account and accept",
        service_P24: "Przelewy24 service",
        service_P24_error: "Accept the regulations Przelewy24",
        choose_currency: "Pay in currency",
        transaction_currency: "Payment currency",
        transaction_actual_amount: "Amount to pay",
        than_you: "Thank you",
        to_continue_the: "To continue the top-up process, check your email address and activate your account",
        pay_with_stripe: "Pay with Stripe"
    },
    contact_support_page: {
        title: "Contact support",
        header: "Send an email",
        select: {
            placeholder: "Select your option",
            placeholder_calling: "Calling",
            placeholder_general: "General",
            placeholder_payments: "Payments",
            placeholder_telebirr: "Telebirr Transfer",
            placeholder_product: "Product",
            placeholder_reff_bonus: "Referral Bonus",
        },
        email: "E-mail",
        name: "Name",
        details: "Details",
        button_send: "Send",
        button_sending: "Sending",
        sended_title: "Your message has been successfully sent.",
        sended_details: " Detailed information has been sent to your e-mail address",
        sended_check_response: "You can check response on",
        support_system_name: "Telephant support system",
        support_using_by: "Using by your e-mail addres and ticket number.",
    },
    invite_bonus_page: {
        title: "Invite Bonus",
        text_page_one: "Can I make calls for free? You can start with T-elephant today! All you have to do is recommend our service to the friends, they will register with a referral code and you will receive a bonus for their first top-up!",
        text_page_two: "This way, you can use the funds received to contact your family or friends and talk even longer at the cheapest rates.",
        text_page_three: "Your referral code you could easily find in your profile in the right corner.",
    },
    t_elephant_page: {
        title: "T-elephant",
        text_page_one: "We face a global migration as a new reality: whether people are fleeing from something or racing towards better opportunity, cultures and are now living in different places far away from home and family. Our mission is to give the possibility to save connections with family and friends all over the word.",
        text_page_two: "T-elephant is a solution to everyone who’s looking for a cheap day by day connection. We have created a product combining technology and experience to reduce the costs of international calls to acceptable rates so that everyday communication does not strain the home budget. The goal was to enable making international calls using a telephone without the need to use additional internet or applications. Simple, fast, cheap and of the highest quality. T-elephant is a way to communicate internationally, for everyone who is calling to connect with their friends and family worldwide.",
        text_page_three: "",
        company_details: "Company details",
    },
    how_it_works_page: {
        poland: 'Poland',
        england: 'England',
        german: "Germany",
        title: "International Top up",
        text_under_title_one: "Service dedicated to international calling / for international callers.",
        call_any_destination: "Call any destination at the lowest price and control your expenses.",
        text_under_title_two: "NO HIDDEN COSTS.",
        text_under_title_three: "Calling has never been so fast, cheap and easy.",
        first_step: "Dial the T-elephant access number (the call is paid according to the operator’s tariff)",
        scond_step: "Enter the PIN number you received during registration (or allow authorization by phone number)",
        thrid_step: "Enter destination number and enjoy long conversations",
        second_tab_title: "Register and receive additional bonuses!",
        second_tab_text: "Recommend the T-elephant to your friends, get your cashback for the topup and use it to be able to talk even longer completely free of charge!",
        thrid_tab_title: "Top up unspent credits to your family!",
        thrid_tab_text: "T-elephant allows you to top up credits to your friends/family/relatives. It’s easy, cheap and safe! Enjoy it!",
    },
    log_in_modal: {
        title: "Welcome Back",
        log_in_with_email: "Log in with your email",
        email: "email",
        password: "Password",
        forgot_password: "Forgot password?",
        button_text: "Log in",
        log_in_with_pin: "Log in with your Pin",
        phone_number: "Phone Number",
        pin: "PIN",
        forgot_pin: "Forgot PIN?",
        wrong_email: "Wrong email or password!",
        wrong_pin: "Wrong phone or pin!",
        log_in_email: "Log in with Email",
    },
    registration_modal: {
        firs_name: "First Name",
        last_name: "Last Name",
        email: "Email",
        phone_number: "Phone number (optional)",
        password: "Password",
        repeat_password: "Repeat password",
        referal_code: "Referal code",
        tos_first_part: "By pressing Register I accept T-Elephant",
        tos: "terms of service",
        and: "and ",
        pp: "privacy policy",
        register_button: "Register",
        sending_info: "Sending",
        thank_you_tittle: "Thank you",
        thank_you_message: "Thank you for registering. To complete registration, please check your email inbox.",
        enter_country_code: "Enter the number in international format with country code",
        for: "for",
        postal_code: "Postal code",
        house_number: "Street and House number",
        city: "City",
        Country: "Country",
    },
    your_account_page: {
        title: "YOUR ACCOUNT",
        top_up: "Top-up",
        acc_balance: "account balance",
        reff_code: "referral code",
        other: "other",
        write_value: "Enter the top-up amount"
    },
    user_profile_page: {
        title: "USER PROFILE",
        decription: "View and update your details right here. Manage your login options and passwords here.",
        edit: "Edit",
        name: "Name",
        language: "Language",
        english: "English",
        search: "Search",
        your_email: "Your email",
        pin_authentication: "Pin authentication",
        phone_indentification: "Phone identification",
        mobile_number: "Mobile number",
        your_pin: "Your pin",
        enabled: "enabled",
        disabled: "disabled",
        update_details: {
            title: "Update my details",
            name: "Name",
            language: "Language",
            email: "Email addres",
            mobile: "Mobile number",
            password: "Your password",
            pin: "Your pin",
            save: "Save",
            loading: "Loading ...",
            change_language: "Change your language",
            english: "English",
            polish: "Polish",
            german: "German",
            your_profile_has: "Your profile has been updated",
        },
        en: "English",
        pl: "Polish",
        de: "German",
    },
    billing_page: {
        title: "Billing",
        from_number: "From number",
        please_wait: " Please wait while we process your request",
        search: "Search",
        date: "Date",
        transaction_type: "Transaction type",
        call_time: "Call time",
        phone_number: "Phone number",
        cost: "Cost",
        status: "Status",
        balance: "Balance",
        empty: "Your Billing is empty",
        from: "from",
        previous: "Previous",
        next: "Next",
        Unavailable: "Unavailable",
        Number_not_allowed: "Number not allowed",
        Daily_limit_for_number_use: "Daily limit for number use",
        Daily_usage_limit: "Daily usage limit",
        Lack_of_funds: "Lack of funds",
        Cancelled: "Cancelled",
        Missed: "Missed",
        Busy: "Busy",
        Received: "Received",
        title_call_history: "Call history"
    },
    log_out_modal: {
        message: "Are you sure you want to log out?",
        yes: "Yes",
        no: "No",
    },
    forgot_password_modal: {
        message: "If you have forgotten your password you can reset it here.",
        email: "Your Email",
        send: "Send My Password",
        sending: "Sending..",
        meessage_send: "If provided email address is registered in our service we will send a message with password reset link.",
    },
    verification_page: {
        failed_title: "Verification failed!",
        failed_message: "Your verification link is either expired or broken.",
        succes_title: "Thank you!",
        succes_message: "Your account has been confirmed",
        wait_for_data: "Please wait as we verify your registration...",
        log_in: "Log in",
        registration_message: "Thank you for registering, your account has been confirmed",
        set_password: "To continue the top-up process, please set your password",
        savePass: "Save",
    },
    termsOfService_pdf: "TermsOfService.pdf",
    cookies_pdf: "Cookie Policy.pdf",
    useCookies: "This website uses cookies",
    clickHere: "Click here",
    click: "Click",
    moreInformation: "for more information.",
    accept: "Accept",
    consent: "to give us your consent",
    thank_you_page: {
        title: "Thank you for topping up",
        notification: "Your funds have just arrived in your account",
        wait_for_payment: "Your funds will be in your account soon, please be patient. Please refresh the page in a moment to check the payment status. If you have any questions, please contact us",
        error_title: "We're sorry we encountered a problem",
        error_notification: "Looks like something went completely wrong! Please contact with us",
        wait_for_data: "Please wait",
    },
    password_reset_page: {
        loading: "Please wait as we process your request...",
        reset_password: "Reset password",
        password_changed: "Password changed!",
        you_can_log_in: "Your password was successfully changed. You can now log in.",
        verification_failed: "Verification failed!",
        failed_info: "Your password reset link is either expired or broken.",
    }
}
