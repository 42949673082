export default {
    logedSatus(state, data){
        state.logedSatus = data;
    },
    amount(state, data){
        state.amount = data;
    },
    otherValue(state, data){
        state.otherValue = data
    },
    showOther(state, data){
        state.showOther = data
    },
}