import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'gbr',    // Language ID
    //this.$i18n.locale // language switching is achieved by switching the value of locale
    messages: {
        'pol': require('./languages/pl'),
        'deu': require('./languages/de'),   
        'gbr': require('./languages/gb'),   
        // 'rus': require('./languages/ru')
    }
});
export {i18n}