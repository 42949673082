<template>
  <div>
    <div>
      <div class="text-center">
        <div v-if="registrationStatus" class="mb-5 pb-3">
          <h3 class="modal-title w-100 h4 my-3 font-weight-bold">
               {{ $t("translate.top_up_modal.than_you") }}
          </h3>
          <span class="bolded">  {{ $t("translate.top_up_modal.to_continue_the") }}</span>
        </div>
        <div v-if="!registrationStatus">
          <h3 v-if="!topUpReady" class="modal-title w-100 h4 my-3 font-weight-bold">
            {{ $t("translate.top_up_modal.button_text") }}
          </h3>
          <h3 v-if="topUpReady" class="modal-title w-100 h4 my-3 font-weight-bold">
            {{ $t("translate.top_up_modal.top_up_summary") }}
          </h3>
          <div v-if="!topUpReady">
            <div class="d-flex align-items-center justify-content-center">
              <div class="mb-3">
                <cake-input
                  v-model="userInfo.firstname"
                  v-bind:type="'text'"
                  v-bind:errors="errors.firstname"
                  v-bind:identifier="'first_name'"
                  v-bind:placeholder="$t('translate.registration_modal.firs_name')"
                  v-bind:isEnabled="isRequestPending"
                />
                <cake-input
                  v-model="userInfo.lastname"
                  v-bind:type="'text'"
                  v-bind:errors="errors.lastname"
                  v-bind:identifier="'last_name'"
                  v-bind:placeholder="$t('translate.registration_modal.last_name')"
                  v-bind:isEnabled="isRequestPending"
                />
                <cake-input
                  v-model="userInfo.email"
                  v-bind:type="'email'"
                  v-bind:errors="errors.email"
                  v-bind:identifier="'email'"
                  v-bind:placeholder="$t('translate.registration_modal.email')"
                  v-bind:isEnabled="isRequestPending"
                />
                <div class="form-group">
                  <label class="control-label">{{
                    $t("translate.top_up_modal.choose_currency")
                  }}</label>
                </div>
                <select class="form-control" v-model="selectedCurrency">
                  <option
                    v-for="(currency, index) in currencies"
                    :key="index"
                    :value="currency.currency"
                  >
                    {{ currency.currency }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row d-flex justify-content-center text-center">
              <div
                v-for="(refill, index) in availableAmounts"
                class="col-md-2 mb-2 mx-md-1 col-6"
                :key="index"
              >
                <a
                  href="#"
                  :class="{ active: refill.amount == amount }"
                  type="button"
                  class="top-up-button ml-sm-0"
                  :for="'amount' + index"
                  @click="changeAmount(refill.amount, index)"
                >
                  <span
                    >{{ refill.amount }}
                    <span v-if="refill.amount != 'Other'">{{
                      currencySymbols[selectedCurrency]
                    }}</span>
                  </span>
                </a>
                <div v-if="refill.promo && isPromoAvailable">
                  + {{ refill.promo }} {{ currencySymbols[selectedCurrency] }}
                  {{ $t("translate.top_up_modal.for_calls") }}
                </div>
              </div>
            </div>
            <transition name="fade">
              <div class="d-flex justify-content-center">
                <div v-if="showOther">
                  <cake-input
                    v-model="otherValue"
                    v-bind:type="'number'"
                    v-bind:errors="errors.amount"
                    v-bind:identifier="'amount'"
                    v-bind:placeholder="$t('translate.your_account_page.write_value')"
                    v-bind:isEnabled="isRequestPending"
                  />
                </div>
              </div>
            </transition>

            <div class="d-flex justify-content-center mt-md-2 mt-2">
              <!-- <a
                href="#"
                :class="{ activeOperator: selectedOperator === 'paypal' }"
                type="button"
                class="top-up-button ml-4"
                @click="changeOperator('paypal')"
              >
                <img
                  class="img-fluid operator-margin"
                  src="/img/telephant/paypal-seeklogo.svg"
                />
              </a> -->
              <a
                href="#"
                :class="{ activeOperator: selectedOperator === 'p24' }"
                type="button"
                class="top-up-button ml-4"
                @click="changeOperator('p24')"
              >
                <img
                  class="img-fluid operator-margin"
                  src="/img/telephant/Przelewy24_logo.svg"
                />
              </a>
              <a
                href="#"
                :class="{ activeOperator: selectedOperator === 'card' }"
                type="button"
                class="top-up-button ml-4 mr-2"
                @click="changeOperator('card')"
              >
                <img
                  class="img-fluid operator-margin credit-card"
                  src="/img/telephant/credit-card-logo.svg"
                />
              </a>
            </div>
            <!-- <div class="d-flex justify-content-center">
              <button
                class="top-up-img-button mr-2"
                type="button"
                :class="{ active: selectedOperator === 'paypal' }"
                @click="changeOperator('paypal')"
              >
                <img
                  class="img-fluid"
                  style="max-height: 50px"
                  src="/img/telephant/paypal-seeklogo.svg"
                />
              </button>
              <button
                class="top-up-img-button mr-2"
                type="button"
                :class="{ active: selectedOperator === 'p24' }"
                @click="changeOperator('p24')"
              >
                <div class="text-wrapper-23">Przelewy24</div>
                <i class="bi bi-bank"></i>
                <img class="img-fluid" src="/img/telephant/bank.svg" />
              </button>
              <button
                class="top-up-img-button"
                type="button"
                :class="{ active: selectedOperator === 'card' }"
                @click="changeOperator('card')"
              >
                <div class="text-wrapper-24">Card</div>
                <img class="img-fluid" src="/img/telephant/credit-card.svg" />
              </button>
            </div> -->
            <div class="">
              <div class="mt-2 mx-3">
                <p>
                  <span>
                    <input
                      class="mr-2"
                      type="checkbox"
                      id="checkbox_id"
                      name="terms"
                      v-model="acceptTos"
                    />{{ $t("translate.top_up_modal.first_part_tos") }}
                    <b class="bolded h5"
                      ><a href="#" @click="getTermsOfService()">
                        {{ $t("translate.top_up_modal.tos") }}
                      </a></b
                    >
                    {{ $t("translate.top_up_modal.and_int_tos") }}
                    <b class="bolded h5"
                      ><a href="#" @click="getPrivacyPolicy()">
                        {{ $t("translate.top_up_modal.pp") }}</a
                      ></b
                    ></span
                  >
                </p>
              </div>
              <div class="mt-2 mx-3">
                <p>
                  <span>
                    <input
                      class="mr-2"
                      :class="{ 'error-checbox': errors.term_and_conditions }"
                      type="checkbox"
                      id="checkbox_id"
                      name="terms"
                      v-model="userInfo.term_and_conditions"
                    />{{ $t("translate.top_up_modal.first_part_tos_P24") }}
                    <b class="bolded h5"
                      ><a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.przelewy24.pl/en/regulations"
                      >
                        {{ $t("translate.top_up_modal.tos_P24") }}
                      </a></b
                    >
                    {{ $t("translate.top_up_modal.and_int_tos_p24") }}
                    <b class="bolded h5"
                      ><a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.przelewy24.pl/en/information-obligation-gdpr-payer"
                      >
                        {{ $t("translate.top_up_modal.pp_P24") }}</a
                      > </b
                    ><br />
                    {{ $t("translate.top_up_modal.service_P24") }}</span
                  >
                </p>
                <small class="text-danger mt-1" v-if="errors.term_and_conditions">{{
                  $t("translate.top_up_modal.service_P24_error")
                }}</small>
              </div>
              <div class="mt-2 mx-3">
                <p>
                  <input
                    class="mr-2"
                    type="checkbox"
                    id="checkbox_id"
                    name="terms"
                    v-model="signIn"
                  />
                  <span class=""
                    >{{ $t("translate.top_up_modal.sign_in") }}
                    <b class="bolded h5"
                      ><a href="#" @click="getTermsOfService()">
                        {{ $t("translate.top_up_modal.tos") }}
                      </a></b
                    >
                    {{ $t("translate.top_up_modal.and_int_tos") }}
                    <b class="bolded h5"
                      ><a href="#" @click="getPrivacyPolicy()">
                        {{ $t("translate.top_up_modal.pp") }}</a
                      ></b
                    ></span
                  >
                </p>
              </div>
              <div class="my-4">
                <button
                  @click="refill"
                  class="btn-log-in"
                  type="button"
                  data-dismiss="logInModal"
                  :disabled="isRequestPending || !acceptTos"
                >
                  {{ $t("translate.top_up_modal.button_text") }}
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="mb-2">
              <span class="bolded">{{ $t("translate.top_up_modal.refill_value") }}: </span
              >{{ amount }}.00 {{ currencySymbols[selectedCurrency] }}
            </p>
            <p class="mb-2">
              <span class="bolded"
                >{{ $t("translate.top_up_modal.first_name_placeholder") }}:</span
              >
              {{ userInfo.firstname }}
            </p>
            <p class="mb-2">
              <span class="bolded"
                >{{ $t("translate.top_up_modal.last_name_placeholder") }}:</span
              >
              {{ userInfo.lastname }}
            </p>
            <p class="mb-4">
              <span class="bolded"
                >{{ $t("translate.top_up_modal.email_placeholder") }}:</span
              >
              {{ userInfo.email }}
            </p>
            <p class="mb-4">
              <span class="bolded"
                >{{ $t("translate.top_up_modal.transaction_currency") }}:</span
              >
              {{ confirmedCurrency }}
            </p>
            <p class="mb-4">
              <span class="bolded"
                >{{ $t("translate.top_up_modal.transaction_actual_amount") }}:</span
              >
              {{ confirmedAmount }} {{ confirmedCurrency }}
            </p>

            <button class="btn-log-in mb-4" type="button" @click="sendP24Refill">
              {{ $t("translate.top_up_modal.pay_with_przelewy24") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CakeInput from "../components/CakeInput.vue";

export default {
  data() {
    return {
      isRequestPending: false,
      isDataReady: false,
      isPromoAvailable: false,
      availableAmounts: [],
      userInfo: {
        firstname: "",
        lastname: "",
        email: "",
        term_and_conditions: false,
      },
      selectedOperator: "p24",
      acceptTos: false,
      paymentId: null,
      tpayId: null,
      notifyUrl: null,
      md5: null,
      topUpReady: false,
      p24link: "",
      errors: "",
      showError: false,
      paymentType: "",
      signIn: "",
      acceptTosP24: false,
      selectedCurrency: "EUR",
      currencies: {},
      confirmedCurrency: "EUR",
      confirmedAmount: 0,
      currencySymbols: {},
      selectedSymbol: "",
      registrationStatus: false,
    };
  },

  components: {
    CakeInput,
  },

  mounted() {
    window.axios.get("/api/customers/refills/list").then((response) => {
      this.isPromoAvailable = response.data.promo;
      this.availableAmounts = response.data.refills;
      this.currencySymbols = response.data.symbols;
      this.isDataReady = true;
    });
    window.axios.get("/api/customers/users/user").then((response) => {
      this.userInfo = response.data;
    });
    window.axios.get("/api/customers/refills/refillExchangeRates").then((response) => {
      this.currencies = response.data;
    });
  },
  computed: {
    amount: {
      get() {
        return this.$store.getters.amount;
      },
      set(value) {
        this.$store.commit("amount", value);
      },
    },
    otherValue: {
      get() {
        return this.$store.getters.otherValue;
      },
      set(value) {
        this.$store.commit("otherValue", value);
      },
    },
    showOther: {
      get() {
        return this.$store.getters.showOther;
      },
      set(value) {
        this.$store.commit("showOther", value);
      },
    },
  },

  methods: {
    refill() {
      if (this.isRequestPending) {
        return;
      }

      if (this.amount == "Other") {
        this.amount = this.otherValue;
      }
      this.isRequestPending = true;
      if (this.signIn == true) {
        window.axios
          .post("/api/customers/users/registerDuringRefill", {
            email: this.userInfo.email,
          })
          .then((response) => {
            if (response.status === 200) {
              this.registrationStatus = true;
            } else {
              this.registrationStatus = false;
            }
          })
          .catch((err) => {
            this.errors = err.response.data;
            this.showError = true;
            this.registrationStatus = false;
          })
          .finally(() => {
            this.isRequestPending = false;
          });
        return;
      }

      if (this.selectedOperator == "p24" || this.selectedOperator == "card") {
        window.axios
          .post("/api/customers/refills/refill", {
            amount: this.amount,
            user: this.userInfo,
            paymentType: this.paymentType,
            currency: this.selectedCurrency,
          })
          .then((response) => {
            this.p24link = response.data.token;
            this.confirmedCurrency = response.data.currency;
            this.confirmedAmount = response.data.amount;
            this.topUpReady = true;
          })
          .catch((err) => {
            this.errors = err.response.data;
            this.showError = true;
          })
          .finally(() => {
            this.isRequestPending = false;
          });
      }
    },
    getTermsOfService() {
      this.isRequestPending = true;
      window.axios
        .get("/api/customers/users/temrsOfServicesPDF", { responseType: "blob" })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;

          let translationName = this.$t("translate.termsOfService_pdf");
          fileLink.setAttribute("download", translationName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    getPrivacyPolicy() {
      this.isRequestPending = true;
      window.axios
        .get("/api/customers/users/privacyPolicyPDF", { responseType: "blob" })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let translationName = this.$t("translate.footer.help.pp");
          fileLink.setAttribute("download", translationName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },

    changeAmount(value) {
      if (value == "Other") {
        this.showOther = true;
      } else {
        this.showOther = false;
      }
      this.amount = value;
    },

    changeOperator(val) {
      this.selectedOperator = val;
      this.paymentType = val;
    },

    sendP24Refill() {
      window.location.replace("https://secure.przelewy24.pl/trnRequest/" + this.p24link);
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.top-up-button {
  width: 118px;
  height: 55px;
  background-color: #ffffff;
  border-radius: 28px;
  border: 1px solid;
  border-color: #ededed;
  box-shadow: 0px 3px 3px #7d7d7d29;
  transform: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 55px;
  text-align: center;
}

.active {
  border-color: #4dff00 !important;
}

.activeOperator {
  border-color: #7ffd00 !important;
  border: 1px solid;
}

.top-up-img-button {
  border-color: white;
  position: relative;
  width: 123px;
  height: 55px;
  border: white !important;
  background-color: #ffffff;
  border-radius: 28px;
  box-shadow: 0px 3px 3px #7d7d7d29;
  margin-bottom: 2rem;
}
</style>
