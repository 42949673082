export default {
    logedSatus(state){
        return state.logedSatus;
    },
    amount(state){
        return state.amount;
    },
    otherValue(state){
        return state.otherValue;
    },
    showOther(state){
        return state.showOther;
    },

}