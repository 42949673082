export const translate = {
    top_bar: {
        services: {
            title: "Tipy a triky",
            authentication: 'Autentizace',
            inter_calling_rates: "Mezinárodní hovory",
            top_up: "Dobíjení",
        },
    help: {
    title: "Nápověda",
    contact_support: "Kontaktovat technickou podporu",
    cookie: "Zásady používání cookies",
    legal_information: "Právní informace",
    tos: "Smluvní podmínky",
    pp: "Zásady ochrany osobních údajů",
    ib: "Pozvánkový bonus",
    },
    about_us: {
    title: "O nás",
    t_elephant: "T-slon",
    how_it_works: "Jak to funguje",
    },
    join: "Registrovat",
    log_in: "Přihlásit se",
    log_out: "Odhlásit se",
    return: "Zpět na panel"
    },
    footer: {
        services: {
            title: "Služby",
            inter_calling_rates: "Ceny mezinárodních hovorů",
        },
         help: {
            title: "Nápověda",
            contact_support: "Kontaktovat technickou podporu",
            cookies: "Zásady používání cookies",
            legal_information: "Právní informace",
            tos: "Smluvní podmínky",
            pp: "Zásady ochrany osobních údajů",
            ib: "Doporučte přítele",
        },
    about_us: {
    title: "O nás",
    t_elephant: "T-slon",
    how_it_works: "Jak to funguje",
    },
    product: {
    title: "Produkty",
    easy_calling: "Snadné volání",
    calling_rates: "Sazby za volání",
    },
    language: {
    title: "Jazyk",
    en: "Anglie",
    de: "Němec",
    ru: "ruština",
    pl: "Polština",
    },
    log_in: "Přihlásit se",
    join: "Registrovat",
    return: "Návrat na panel",
    log_out: "Odhlásit se",
    },
    side_bar: {
    your_accoutn: "Váš účet",
    user_profile: "Uživatelský profil",
    billing: "Fakturace",
    call_history: "Historie hovorů",
    },
    welcome_page: {
    cheapy_and_easy: "Levné a jednoduché mezinárodní telefonní hovory",
    you_are_three: "Jste tři kroky od svých blízkých ve vaší zemi:",
    create_an_account_by: "vytvořte si účet zadáním své e-mailové adresy a volitelně telefonního čísla",
    top_up_your_account: "Dobijte si účet pomocí pohodlného způsobu platby",
    call_with_authorization: "volání pomocí PIN nebo telefonního čísla",
    creating_account: "Vytvoření účtu",
    to_set_up_a_call_email: "K vytvoření účtu potřebujete pouze e-mailovou adresu",
    to_set_up_a_call_authorization: "Můžete také zadat telefonní číslo a autorizovat jej k volání bez zadání čísla PIN",
    new_account: "Nový účet",
    phone_number_authorization: "Autorizace telefonního čísla",
    if_you_authorize_your_phone: "Pokud autorizujete své telefonní číslo pro uskutečnění hovoru, nebudete muset zadávat svůj PIN.",
    to_perform_authorization: "Chcete-li provést autorizaci, přihlaste se ke svému účtu a přejděte do svého profilu a vyberte metodu autorizace",
    the_lowest_prices: "Nejnižší ceny a pohodlí při používání",
    offers_the_cheapest_phone: "T-elephant nabízí nejlevnější telefonní hovory do zemí po celém světě",
    no_hidden_fees: "Žádné skryté poplatky a není třeba instalovat aplikace",
    select_the_country_you_want: "Vyberte zemi, do které chcete volat - zkontrolujte ceny, dobijte si účet a zavolejte!",
    spend_your_money: "Utrácejte peníze za to, co je důležité, ne za drahé telefonáty",
    international_calling: "Vyberte si, kam chcete volat a zkontrolujte naše ceny:",
    international_calling_placeholder: "Kam chcete volat?",
    cirrcle_one_first_line: "mezinárodní nejlepší",
    cirrcle_one_second_line: "sazby za volání,",
    cirrcle_one_thrid_line: "nejlepší kvalita za nejlepší cenu",
    cirrcle_two_first_line: "Aplikaci nemusíte stahovat",
    cirrcle_two_second_line: "Použij svůj telefon - je to snadné",
    cirrcle_two_thrid_linie: "A rychle – dosáhněte svého",
    cirrcle_two_fourth_line: "Rodina a přátelé!",
    cirrcle_three_first_line: "více než vizitka",
    cirrcle_three_second_line: "připojte se a získejte",
    cirrcle_three_thrid_line: "propagační akce/bonusy/odměny",
    how_work_title: "Jak mohu volat?",
    how_work_text_point_1: "1. Zavolejte na přístupové číslo",
    how_work_text_point_2: "2. Zadejte svůj PIN (pokud autorizujete své telefonní číslo, váš PIN nebude vyžadován)",
    how_work_text_point_3: "3. Zadejte číslo své destinace a užívejte si levné volání!",
    Poland: "Polsko:",
    Germany: "Německo:",
    Great_britan: "Velká Británie:",
    text_button: "Další informace",
    world_credits: "Credits of the World",
    cheapest: "nejlevnější volání do ",
    and: "a",
    show_all_rates: "zobrazit všechny ceny",
    buy_now: "Koupit nyní",
    minutes: "min",
    per_minute: "za minutu",
    },
    authentication_page: {
    first_title: "Autorizuj se svým telefonem a užívej si hovory!",
    text: "Telephant vám poskytuje zabezpečený přístup k vašemu účtu. Pomocí ověření PIN nebo ID telefonu můžete snadno autorizovat volání na vámi zvolené číslo. Přejděte do uživatelského profilu a vyberte si vhodný způsob, jakým jej chcete používat.",
    secondt_title: "Jak se dobíjím? Je to jednoduché",
    first_step: "Přihlaste se ke svému účtu T-elephant",
    second_step: "Vyberte tlačítko Profil uživatele",
    green_button_text: "Uživatelský profil",
    thrid_step: "Vyberte možnosti (bude označeno zeleným rámečkem)"
    },
    inter_calling_rates_page: {
    text_title_bold: "Sazby za mezinárodní hovory",
    text_title: "Zkuste mezinárodní hovory T-elephant",
    list_first_el: "Nejlevnější mezinárodní spojení vysoké kvality",
    list_secont_el: "Žádné skryté poplatky - plaťte průběžně",
    list_third_el: "Není třeba instalovat žádnou další aplikaci",
    list_fourth_el: "Nepotřebujete internet",
    bottom_text_small: "T-elephant je způsob, jak zůstat v kontaktu s přáteli a rodinou po celém světě!",
    bottom_text_normal: "Bez ohledu na to, odkud voláte - své náklady máte pod kontrolou!",
    title_select: "Podívejte se na naši nabídku",
    select_placeholder: "Kam chcete volat?",
    how_it_works: "Jak to funguje",
    },
    top_up_modal: {
    first_name_placeholder: "Jméno",
    last_name_placeholder: "Příjmení",
    email_placeholder: "E-mail",
    phone_number_placeholder: "Telefonní číslo",
    first_part_tos: "Přijímám",
    tos: "Podmínky služby",
    and_int_tos: "a",
    pp: "Zásady ochrany osobních údajů",
    button_text: "Dobít",
    top_up_summary: "Souhrn dobíjení",
    for_calls: "pro hovory!",
    refill_value: "Dobití",
    pay_with_przelewy24: "Přejít k platbě",
    first_part_tos_P24: "Prohlašuji, že jsem četl",
    tos_P24: "předpisy",
    and_int_tos_p24: "a ",
    pp_P24: "informační povinnost",
    sign_in: "Vytvořit účet a přijmout",
    service_P24: "Služba Przelewy24",
    service_P24_error: "Přijměte smluvní podmínky služby Przelewy24",
    choose_currency: "Vybrat měnu dobití",
    transaction_currency: "Měna platby",
    transaction_actual_amount: "Částka v měně platby",
    than_you: "Děkuji",
    to_continue_the: "Chcete-li pokračovat v procesu dobíjení, zkontrolujte svou e-mailovou adresu a aktivujte svůj účet",
    },
    contact_support_page: {
        title: "Kontaktovat technickou podporu",
        header: "Odeslat e-mail",
        select: {
        placeholder: "Vyberte téma",
        placeholder_calling: "Připojení",
        placeholder_general: "Obecné",
        placeholder_payments: "Platby",
        placeholder_telebirr: "Telebirr Transfer",
        placeholder_product: "Služba",
        placeholder_reff_bonus: "Bonus za doporučení",
    },
    email: "E-mail",
    name: "Jméno",
    details: "Podrobnosti",
    button_send: "Odeslat",
    button_sending: "Odesílání",
    sended_title: "Vaše zpráva byla úspěšně odeslána",
    sended_details: "Na vaši e-mailovou adresu byly zaslány podrobné informace",
    sended_check_response: "Odpověď můžete zkontrolovat na",
    support_system_name: "Stránka zákaznické podpory Telephant",
    support_using_by: "Pomocí vaší e-mailové adresy a čísla lístku.",
    },
    invite_bonus_page: {
    title: "Pozvánkový bonus",
    text_page_one: "Mohu volat zdarma? S T-elephant můžete začít ještě dnes! Jediné, co musíte udělat, je doporučit naši službu svým přátelům, kteří se zaregistrují pomocí doporučujícího kódu, a obdržíte bonus za její první dobití!",
    text_page_two: "Tímto způsobem můžete použít prostředky, které obdržíte, ke kontaktování své rodiny nebo přátel a vést ještě delší rozhovor za nejnižší sazby",
    text_page_three: "Svůj doporučující kód snadno najdete po přihlášení v pravém dolním rohu",
    },
    t_elephant_page: {
        title: "T-elephant",
    text_page_one: "Globální migraci vnímáme jako novou realitu: ať už lidé před něčím utíkají nebo spěchají za lepšími příležitostmi, a my nyní žijeme na různých místech, daleko od domova a rodiny. Naším posláním je dát vám příležitost udržovat a budovat vztahy s rodinou a přáteli po celém světě,",
    text_page_two: "T-elephant je řešením pro všechny, kteří každý den hledají levné připojení. Vytvořili jsme produkt, který kombinuje technologie a zkušenosti s cílem snížit náklady na mezinárodní volání na přijatelné sazby, aby každodenní komunikace nezatěžovala rozpočet domácnosti. Cílem bylo uskutečňovat mezinárodní hovory z telefonu bez potřeby dalšího internetu nebo aplikací. Jednoduché, rychlé, levné a v nejvyšší kvalitě. T-elephant je způsob mezinárodní komunikace pro každého, kdo volá, aby se spojil se svými přáteli a rodinou po celém světě.",
    text_page_three: "",
    company_details: "Údaje o společnosti",
    },
    how_it_works_page: {
        poland: "Polsko",
        england: "Anglie",
        german: "Německo",
        title: "Mezinárodní dobíjení",
    text_under_title_one: "Služba určená pro mezinárodní hovory / pro volající ze zahraničí.",
    call_any_destination: "Volejte kamkoliv za nejnižší cenu a kontrolujte své výdaje",
    text_under_title_two: "ŽÁDNÉ SKRYTÉ NÁKLADY",
    text_under_title_three: "Volání ještě nikdy nebylo tak rychlé, levné a snadné.",
    first_step: "Vytočte přístupové číslo T-elephant (hovor je zpoplatněn podle tarifu operátora)",
    scond_step: "Zadejte číslo PIN, které jste obdrželi při registraci (nebo povolte autorizaci pomocí telefonního čísla)",
    thrid_step: "Zadejte cílové číslo a užijte si dlouhé rozhovory",
    second_tab_title: "Zaregistrujte se a získejte další bonusy!",
    second_tab_text: "Doporučte T-elephant svým přátelům, získejte cashback za dobití a využijte ho k tomu, abyste si mohli povídat ještě déle zcela zdarma!",
    thrid_tab_title: "Dobijte si rodinný nevyužitý kredit!",
    thrid_tab_text: "T-elephant vám umožňuje dobíjet kredity pro přátele/rodinu/příbuzné. Je to jednoduché, levné a bezpečné!",
    },
    log_in_modal: {
    title: "Vítejte zpět",
    log_in_with_email: "Přihlaste se pomocí své e-mailové adresy",
    email: "e-mail",
    password: "Heslo",
    forgot_password: "Zapomněli jste heslo?",
    button_text: "Přihlásit se",
    log_in_with_pin: "Přihlásit se pomocí svého PIN",
    phone_number: "Telefonní číslo",
    pin: "PIN",
    forgot_pin: "Zapomněli jste PIN?",
    wrong_email: "Neplatná e-mailová adresa nebo heslo!",
    wrong_pin: "Neplatný telefon nebo PIN kód!",
    log_in_email: "Přihlaste se pomocí své e-mailové adresy",
    },
    registration_modal: {
    firs_name: "Jméno",
    last_name: "Příjmení",
    email: "E-mail",
    phone_number: "Telefonní číslo (volitelné)",
    password: "Heslo",
    repeat_password: "Zopakovat heslo",
    referal_code: "Doporučující kód",
    tos_first_part: "Stisknutím tlačítka Vytvořit účet přijímám T-Elephant",
    tos: "Podmínky služby",
    a: "a ",
    pp: "Zásady ochrany osobních údajů",
    register_button: "Vytvořit účet",
    sending_info: "Odesílání",
    thank_you_tittle: "Děkuji vám",
    thank_you_message: "Děkujeme za registraci. Pro dokončení registrace si prosím zkontrolujte svou e-mailovou schránku.",
    enter_country_code: "Zadejte číslo v mezinárodním formátu s kódem země",
    for: "pro",
    postal_code: "Poštovní směrovací číslo",
    house_number: "Ulice a číslo popisné",
    city: "Město",
    Country: "Země"
    },
    your_account_page: {
    title: "VÁŠ ÚČET",
    top_up: "Dobít",
    acc_balance: "Zůstatek na účtu",
    reff_code: "doporučující kód",
    other: "jiná částka",
    write_value: "Zadejte dobitou částku",
    },
    user_profile_page: {
    title: "PROFIL UŽIVATELE",
    decription: "Zkontrolujte a aktualizujte svá data. Zde můžete spravovat možnosti přihlášení a hesla.",
    edit: "Upravit",
    name: "Jméno",
    language: "Jazyk",
    english: "Angličtina",
    search: "Hledat",
    your_email: "Vaše e-mailová adresa",
    pin_authentication: "Ověření PINem",
    phone_indentification: "Telefonická identifikace",
    mobile_number: "Číslo mobilního telefonu",
    your_pin: "Váš pin",
    enabled: "zpřístupněný",
    disabled: "Zakázáno",
    update_details: {
    title: "Aktualizovat moje data",
    name: "Jméno",
    language: "Jazyk",
    email: "Odeslat e-mail na adresu",
    mobile: "Číslo mobilního telefonu",
    password: "Vaše heslo",
    pin: "Váš pin",
    save: "Uložit",
    loading: "Načítání ...",
    change_language: "Změnit jazyk",
    english: "Angličtina",
    polish: "polština",
    german: "německy",
    your_profile_has: "Váš profil byl aktualizován",
    },
    en: "Angličtina",
    pl: "Polština",
    de: "Němec",
    },
    billing_page: {
    title: "Fakturace",
    from_number: "Z čísla",
    please_wait: "Počkejte prosím, než zpracujeme vaši žádost",
    search: "Hledat",
    date: "Datum",
    transaction_type: "Typ transakce",
    call_time: "Doba připojení",
    phone_number: "Telefonní číslo",
    cost: "Náklady",
    status: "Stav",
    balance: "Zůstatek",
    empty: "Váš příběh je prázdný",
    from: "od",
    previous: "Předchozí",
    next: "Další",
    Unavailable: "Není k dispozici",
    Number_not_allowed: "Zakázané číslo",
    Daily_limit_for_number_use: "Bylo dosaženo denního limitu",
    Daily_usage_limit: "Bylo dosaženo denního limitu",
    Lack_of_funds: "Nedostatek finančních prostředků",
    Cancelled: "Zrušeno",
    Missed: "Zmeškaný",
    Busy: "Obsazeno",
    Received: "Přijal",
    title_call_history: "Historie hovorů",
    },
    log_out_modal: {
    message: "Opravdu se chcete odhlásit?",
    yes: "Ano",
    no: "Ne",
    },
    forgot_password_modal: {
        message: "Pokud jste zapomněli heslo, můžete ho obnovit zde",
    email: "vaše e-mailová adresa",
    send: "Odeslat mé heslo",
    sending: "Odesílám..",
    meessage_send: "Pokud je Vámi zadaná e-mailová adresa zaregistrována na našich webových stránkách, zašleme Vám e-mail s odkazem na obnovení hesla",
    },
    verification_page: {
    failed_title: "Ověření se nezdařilo!",
    failed_message: "Platnost vašeho ověřovacího odkazu vypršela nebo je nefunkční",
    succes_title: "Děkuji!",
    succes_message: "Váš účet byl ověřen",
    wait_for_data: "Počkejte prosím, dokud neověříme vaši registraci...",
    log_in: "Přihlásit se",
    registration_message: "Děkujeme za registraci, váš účet byl aktivován",
    set_password: "Chcete-li pokračovat v procesu dobíjení, nastavte heslo",
    savePass: "Uložit",
    },
    termsOfService_pdf: "Regulamin.pdf",
    cookies_pdf: "Cookie Policy.pdf",
    useCookies: "Tento web používá cookies",
    clickHere: "Klikněte zde",
    click: "Klikněte",
    moreInformation: "pro více informací",
    accept: "přijmout",
    consent: "udělit souhlas",
    thank_you_page: {
    title: "Děkujeme za dobití",
    notification: "Vaše prostředky právě dorazily na váš účet",
    wait_for_payment: "Vaše prostředky budou brzy na vašem účtu, buďte prosím trpěliví. Obnovte prosím stránku a zkontrolujte stav své platby. V případě jakýchkoli dotazů nás prosím kontaktujte",
    error_title: "Je nám líto, že jsme narazili na problém",
    error_notification: "Vypadá to, že se něco úplně pokazilo! Kontaktujte nás",
    wait_for_data: "Čekejte prosím",
    },
    password_reset_page:{
    loading: "Čekejte prosím, zpracováváme váš požadavek...",
    reset_password: "Obnovit heslo",
    password_changed: "Heslo změněno!",
    you_can_log_in: "Vaše heslo bylo úspěšně změněno. Nyní se můžete přihlásit.",
    verification_failed: "Ověření se nezdařilo!",
    failed_info: "Platnost vašeho odkazu pro obnovení hesla vypršela nebo je nefunkční.",
    }
    }
    
    
    